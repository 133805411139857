<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <bannerslider/>
            </div>
        </div>
        <section class="blockElement bg-white space overflow-hidden gdpCompare">
            <div class="container">
                <div class="row space-footer">
                    <div class="col-12 mb-md-5">
                        <div class="breadCrum">
                            <h2>GDP Growth Rate by Country  - <span class="green">World</span></h2>
                            <nav aria-label="breadcrumb mt-2">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                    <li class="breadcrumb-item"><router-link class="green" to="/indicator-list">Indicator List</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">GDP Growth Rate by Country  - World </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9 mb-4 mb-xl-0">
                        <div class="d-flex align-items-center justify-content-end mb-3">
                            <a @click.prevent="listGrid = 'viewList'" :class="(listGrid == 'viewList')?'active':''" class="viewGrid me-2 maskImg" href="javascript:void(0)"><span class="icon icon1"></span></a>
                            <a @click.prevent="listGrid = 'viewGrid'" :class="(listGrid == 'viewGrid')?'active':''" class="viewGrid me-2 maskImg" href="javascript:void(0)"><span class="icon icon2"></span></a>
                        </div>
                        <div class="table-responsive nesting border" v-if="listGrid == 'viewList'">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Last</th>
                                        <th>Previous</th>
                                        <th>Reference</th>
                                        <th>Unit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>India</td>
                                        <td><span class="alert alert-danger">-10.2</span></td>
                                        <td class="green">2.3</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Nigeria</td>
                                        <td><span class="alert alert-danger">-10.2</span></td>
                                        <td class="red">-0.8</td>
                                        <td>Sep/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Moldova</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Saudi Arabia</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Colombia</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Chile</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Kuwait</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Austria</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Norway</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Philippines</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Bahrain</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Russia</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>France</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="green">3.9</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                    <tr>
                                        <td>Belize</td>
                                        <td><span class="alert alert-success">7.0</span></td>
                                        <td class="red">-7.1</td>
                                        <td>Jun/21</td>
                                        <td>%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="gridChart" v-if="listGrid == 'viewGrid'">
                            <img class="w-100" src="assets/images/chart/gridChart.svg" alt="Icon" title="Grid GDP Growth Chart">
                        </div>
                    </div> 
                    <div class="col-12 col-xl-3 space ps-xl-5">
                        <div class="halfBG space secondaryBG">
                            <div class="position-relative">
                                <div class="border-bottom trade pb-3 mb-4">
                                    <h2>Other Indicators</h2>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '1'" :class="(indicatorsTabs == '1')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/global.svg" alt="global" title="GDP"> GDP</span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '1')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '2'" :class="(indicatorsTabs == '2')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/company.svg" alt="global" title="GDP"> Markets </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '2')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '3'" :class="(indicatorsTabs == '3')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/labour.svg" alt="global" title="GDP"> Labour </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '3')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '4'" :class="(indicatorsTabs == '4')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/price.svg" alt="global" title="GDP"> Prices </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '4')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '5'" :class="(indicatorsTabs == '5')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/health.svg" alt="global" title="GDP"> Health </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '5')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '6'" :class="(indicatorsTabs == '6')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/indicator.svg" alt="global" title="GDP"> Trade </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '6')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '7'" :class="(indicatorsTabs == '7')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/business.svg" alt="global" title="GDP"> Business </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '7')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '8'" :class="(indicatorsTabs == '8')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="d-flex align-items-center"><img class="me-2" src="assets/images/government.svg" alt="global" title="GDP"> Government </span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '8')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
export default {
    data() {
        return {
            countryTabs:'Europe',
            indicatorsTabs:'1',
            actualTab:'Actual',
            counttryTabs:false,
            listGrid:'viewList',
        }
    },
    components: {
        bannerslider,
    },     
}
</script>
